import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "card"
}
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "card-toolbar" }
const _hoisted_5 = {
  class: "d-flex justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_6 = { class: "svg-icon svg-icon-2" }
const _hoisted_7 = { class: "card-body pt-0" }
const _hoisted_8 = {
  key: 0,
  class: "badge badge-light-success"
}
const _hoisted_9 = {
  key: 1,
  class: "badge badge-light-danger"
}
const _hoisted_10 = ["onClick"]
const _hoisted_11 = { class: "svg-icon svg-icon-3" }
const _hoisted_12 = ["onClick"]
const _hoisted_13 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg = _resolveComponent("inline-svg")!
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.can('read', 'instant-rent-estimator'))
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("p", null, _toDisplayString(_ctx.translate('IreFactorsPropertySeriveListing')), 1)
            ]),
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("div", _hoisted_5, [
                (_ctx.can('create', 'instant-rent-estimator'))
                  ? (_openBlock(), _createElementBlock("button", {
                      key: 0,
                      type: "button",
                      class: "btn btn-primary",
                      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goTo('ire-investment-factors-Property-service-creating')))
                    }, [
                      _createElementVNode("span", _hoisted_6, [
                        _createVNode(_component_inline_svg, { src: "/media/icons/duotone/Navigation/Plus.svg" })
                      ]),
                      _createTextVNode(" " + _toDisplayString(_ctx.translate('addNewIreFactorsPropertyService')), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ])
          ]),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_Datatable, {
              "table-data": _ctx.tableData,
              "table-header": _ctx.tableHeader,
              loading: _ctx.loading,
              "rows-per-page": 10,
              total: _ctx.totalItems,
              "enable-items-per-page-dropdown": false,
              onCurrentChange: _ctx.pageChanged
            }, {
              "cell-id": _withCtx(({ row: ireFactorsPropertyServices }) => [
                _createTextVNode(_toDisplayString(ireFactorsPropertyServices.id), 1)
              ]),
              "cell-titleEn": _withCtx(({ row: ireFactorsPropertyServices }) => [
                _createTextVNode(_toDisplayString(ireFactorsPropertyServices.titleEn), 1)
              ]),
              "cell-titleAr": _withCtx(({ row: ireFactorsPropertyServices }) => [
                _createTextVNode(_toDisplayString(ireFactorsPropertyServices.titleAr), 1)
              ]),
              "cell-sort": _withCtx(({ row: ireFactorsPropertyServices }) => [
                _createTextVNode(_toDisplayString(ireFactorsPropertyServices.sort), 1)
              ]),
              "cell-isActive": _withCtx(({ row: ireFactorsPropertyServices }) => [
                (ireFactorsPropertyServices.isActive)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.translate('active')), 1))
                  : (_openBlock(), _createElementBlock("span", _hoisted_9, _toDisplayString(_ctx.translate('inactive')), 1))
              ]),
              "cell-actions": _withCtx(({ row: ireFactorsPropertyServices }) => [
                _createElementVNode("a", {
                  href: "#",
                  class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1",
                  onClick: _withModifiers(($event: any) => (
                _ctx.editIreFactorsPropertyService(ireFactorsPropertyServices.id)
              ), ["prevent"])
                }, [
                  _createElementVNode("span", _hoisted_11, [
                    _createVNode(_component_inline_svg, { src: "/media/icons/duotune/art/art005.svg" })
                  ])
                ], 8, _hoisted_10),
                (_ctx.can('remove', 'instant-rent-estimator'))
                  ? (_openBlock(), _createElementBlock("a", {
                      key: 0,
                      href: "#",
                      class: "btn btn-icon btn-bg-light btn-active-color-primary btn-sm",
                      onClick: _withModifiers(($event: any) => (
                _ctx.toggleStatusIreFactorsPropertService(ireFactorsPropertyServices)
              ), ["prevent"])
                    }, [
                      _createElementVNode("span", _hoisted_13, [
                        _createVNode(_component_inline_svg, { src: "/media/icons/duotune/general/gen019.svg" })
                      ])
                    ], 8, _hoisted_12))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["table-data", "table-header", "loading", "total", "onCurrentChange"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}